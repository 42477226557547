import * as React from 'react'
import PropTypes from 'prop-types'
import { SitemapLink as Link } from './SitemapLink'

const SitemapListGroup = ({ title, url, content, thirdLevel }) => {
  return (
    <div>
      <div className={'flex justify-between items-center break-after-avoid'}>
        <Link className={'py-4 text-left '} to={`/sitemap${url}`}>
          <h2 className="sm:text-xl text-red-700 font-bold uppercase">{title}</h2>
        </Link>
      </div>
      <div>
        {content.length > 0 &&
          content.map(secLevelPage => {
            const relatedThirdLevel = thirdLevel.filter(
              category => category.url.indexOf(secLevelPage.url) === 0
            )
            return (
              <div key={secLevelPage.name}>
                <h3 className="text-base font-bold"><Link to={`/sitemap${secLevelPage.url}`}>{secLevelPage.name}</Link></h3>
                <div className="flex flex-col">
                  {relatedThirdLevel.length > 0 &&
                    relatedThirdLevel.map(category => {
                      return (
                        <h4 className="pl-4 text-base" key={category.name}>
                          <Link to={`/sitemap${category.url}`}>
                            {category.name}
                          </Link>
                        </h4>
                      )
                    })}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}
SitemapListGroup.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      path: PropTypes.string,
      name: PropTypes.string,
      blog: PropTypes.bool,
      press_release: PropTypes.bool,
    })
  ),
  thirdLevel: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      path: PropTypes.string,
      name: PropTypes.string,
      blog: PropTypes.bool,
      press_release: PropTypes.bool,
    })
  ),
}

export { SitemapListGroup } 
