import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import PropTypes from 'prop-types'
import { uniformUrl } from '../helpers/StringHelpers'
import NewArrivalSlider from '../components/sliders/NewArrivalSlider'
import Seo from '../components/Seo'
import { SitemapLink as Link } from '../components/sitemap/SitemapLink'
import { SitemapListGroup as ListGroup } from '../components/sitemap/SitemapListGroup'
import { ServiceIcons } from '../components/repairs/BrandRepairServices'

const SitemapGroup = ({ title = 'Other Links', image = <></>, children }) => {
  return (
    <div
      className={
        'mx-auto flex sm:w-128 xl:w-full flex-col gap-2 md:container md:flex-column md:py-5'
      }
    >
      <div
        className={
          'grid sm:grid-cols-[250px_minmax(100px,_1fr)] lg:grid-cols-[300px_minmax(100px,_1fr)] gap-8'
        }
      >
        <div className="sticky top-0 bg-white py-2">
          <div className="flex sm:flex-col sm:sticky top-0 items-center gap-3">
            <div className="z-20 mb-8 font-black text-gray-700 text-2xl uppercase w-3/4 sm:w-full order-2 sm:order-1">
              {title}
            </div>
            {image}
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
SitemapGroup.propTypes = {
  title: PropTypes.string,
  image: PropTypes.node,
  children: PropTypes.node,
}

const SitemapPageGroup = ({ title = 'Other Links', image = <></>, pages }) => {
  return (
    <SitemapGroup title={title} image={image}>
      <div className={'lg:columns-2 xl:columns-3 space-y-5 pt-10 border-t-2 border-red-700'}>
        {pages.map(page => {
          return (
            <Link key={page.title} className=" text-left block" to={`${uniformUrl(page.slug)}`}>
              {page.title}
            </Link>
          )
        })}
      </div>
    </SitemapGroup>
  )
}

SitemapPageGroup.propTypes = {
  title: PropTypes.string,
  image: PropTypes.node,
  pages: PropTypes.array,
}

const SitemapIndexPage = () => {
  const sitemap = useStaticQuery(graphql`
    query SitemapIndexPageQuery {
      pages: allStrapiPage {
        nodes {
          id
          slug: urlPath
          title
        }
      }
      blogs: allStrapiBlogPost {
        nodes {
          id
          slug: urlPath
          title
        }
      }
      repairBlogs: allStrapiRepairBlogCategory {
        nodes {
          id
          slug: urlPath
          brand
        }
      }
      repairBlogPosts: allStrapiRepairBlogPost {
        nodes {
          id
          slug: urlPath
          brand {
            brand
          }
          model
          featuredService
        }
      }
      categories: allStrapiCategory {
        nodes {
          id
          url: urlPath
          strapi_id
          name
          level
        }
      }
    }
  `)
  const pages = sitemap.pages.nodes
  const blogs = sitemap.blogs.nodes
  const repairBlogs = sitemap.repairBlogs.nodes
  const repairBlogPosts = sitemap.repairBlogPosts.nodes
  const categories = sitemap.categories.nodes
  const priority = [
    'Fine Luxury Watches',
    'Luxury Jewelry',
    'Watch Bands & Straps',
    'New Arrivals',
  ].reverse()

  const firstLevel = categories
    .filter(category => category.level === 1)
    .map(item => {
      return { ...item, order: -priority.indexOf(item.name) }
    })
    .sort((a, b) => {
      if (a.order === b.order) {
        return a.name > b.name ? 1 : -1
      }
      return a.order > b.order ? 1 : -1
    })
  const secondLevel = categories.filter(category => category.level === 2)
  const thirdLevel = categories.filter(category => category.level === 3)
  return (
    <Layout
      h1={'Site Map'}
      title={'Site Map | Gray and Sons'}
      className={'mx-auto w-10/12'}
      breadcrumbs={false}
    >
      <Seo
        title={'Site Map | Gray and Sons'}
        noindex={false}
        description={
          'Site Map | Explore our selection of used jewelry and watches, find the perfect new addition for your style story. | Gray and Sons'
        }
        canonical="/sitemap/"
      />
      <SitemapGroup
        title={'Product Categories'}
        image={
          <StaticImage
            src={'../images/sell-my-watch-form-header.png'}
            alt={'Watches and Jewelry'}
            className={'mx-auto sm:w-full w-1/4 order-1 sm:order-2'}
            objectFit="contain"
            placeholder="blurred"
          />
        }
      >
        <div
          className={'gap-12 border-t-2 border-red-700 lg:columns-2 xl:columns-3 space-y-3 pt-3'}
        >
          {firstLevel.map(page => {
            const relatedSecondLevel = secondLevel.filter(
              category => category.url.indexOf(page.url) === 0
            )
            return (
              <div key={page.name} className={'break-inside-avoid block'}>
                {relatedSecondLevel.length > 0 ? (
                  <ListGroup
                    title={page.name}
                    url={page.url}
                    content={relatedSecondLevel}
                    thirdLevel={thirdLevel}
                  />
                ) : (
                  <Link className={'py-4 text-left '} to={`/sitemap${page.url}`}>
                    <h2 className="sm:text-xl text-red-700 font-bold uppercase">{page.name}</h2>
                  </Link>
                )}
              </div>
            )
          })}
        </div>
      </SitemapGroup>
      <SitemapGroup
        title={'Repair Blogs'}
        image={
          <StaticImage
            src={'../images/best-watch-used-jewelry-repair-blogs.png'}
            alt={'Blogs'}
            className={'mx-auto sm:w-full w-1/4 order-1 sm:order-2'}
            objectFit="contain"
            placeholder="blurred"
          />
        }
      >
        <div
          className={'gap-12 border-t-2 border-red-700 lg:columns-2 xl:columns-3 space-y-3 pt-3'}
        >
          <div>
            <Link className={'py-4 text-left block '} to={uniformUrl('/repairs/')}>
              <h2 className="sm:text-xl text-red-700 font-bold uppercase">Featured Services</h2>
            </Link>
            {ServiceIcons.map(i => {
              const relatedSecondLevel = repairBlogPosts.filter(
                post => !post?.brand && post.featuredService === i.label && post.slug !== i.url
              )
              return (
                <div key={i.url} className={'break-inside-avoid block'}>
                  <div>
                    <div className={'flex justify-between items-center break-after-avoid'}>
                      <Link className={'text-left '} to={uniformUrl(i.url)}>
                        <h3 className="text-base font-bold">{i.label}</h3>
                      </Link>
                    </div>
                    <div>
                      {relatedSecondLevel.length > 0 &&
                        relatedSecondLevel.map(secLevelPage => {
                          return (
                            <ul className="list-disc ml-4 " key={secLevelPage.slug}>
                              <li>
                                <Link to={uniformUrl(secLevelPage.slug)}>
                                  <h4 className="text-base font-bold">
                                    {secLevelPage.model} {secLevelPage.featuredService}
                                  </h4>
                                </Link>
                              </li>
                            </ul>
                          )
                        })}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          {repairBlogs.map(repairBlogCategory => {
            const relatedSecondLevel = repairBlogPosts.filter(
              post => post?.brand?.brand === repairBlogCategory.brand
            )
            return (
              <div key={repairBlogCategory.slug} className={'break-inside-avoid block'}>
                <div>
                  <div className={'flex justify-between items-center break-after-avoid'}>
                    <Link className={'py-4 text-left '} to={uniformUrl(repairBlogCategory.slug)}>
                      <h2 className="sm:text-xl text-red-700 font-bold uppercase">
                        {repairBlogCategory.brand} Watch Repairs
                      </h2>
                    </Link>
                  </div>
                  <div>
                    {relatedSecondLevel.length > 0 &&
                      relatedSecondLevel.map(secLevelPage => {
                        return (
                          <div key={secLevelPage.slug}>
                            <h3 className="text-base font-bold">
                              <Link to={uniformUrl(secLevelPage.slug)}>
                                {secLevelPage.brand.brand} {secLevelPage.model}{' '}
                                {secLevelPage.featuredService} Repair
                              </Link>
                            </h3>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </SitemapGroup>
      <SitemapPageGroup
        pages={blogs}
        title="Blogs"
        image={
          <StaticImage
            src={'../images/watch-and-jewelry-information-blogs.png'}
            alt={'Blogs'}
            className={'mx-auto sm:w-full w-1/4 order-1 sm:order-2'}
            objectFit="contain"
            placeholder="blurred"
          />
        }
      />
      <SitemapPageGroup
        pages={pages}
        title="Other Links"
        image={
          <StaticImage
            src={'../images/other-links.png'}
            alt={'Watches and Jewelry Image'}
            className={'mx-auto sm:w-full w-1/4 order-1 sm:order-2'}
            objectFit="contain"
            placeholder="blurred"
          />
        }
      />
      <NewArrivalSlider />
    </Layout>
  )
}

SitemapIndexPage.propTypes = {
  pageContext: PropTypes.shape({
    pageId: PropTypes.string,
    categoryName: PropTypes.string,
    listPages: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        path: PropTypes.string,
        name: PropTypes.string,
        blog: PropTypes.bool,
        press_release: PropTypes.bool,
      })
    ),
  }),
  data: PropTypes.object,
}

SitemapIndexPage.defaultProps = {
  pageContext: null,
  data: null,
}

export default SitemapIndexPage
