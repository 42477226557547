import * as React from 'react'
import { Link as LinkGatsby } from 'gatsby'
import PropTypes from 'prop-types'

const SitemapLink = ({ to, className, children }) => {
  return (
    <LinkGatsby className={className + ' text-gray-600 hover:text-red-700'} to={to}>
      {children}
    </LinkGatsby>
  )
}
SitemapLink.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}

export { SitemapLink } 
